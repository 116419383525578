import { 
    useState,
    useEffect,
    useRef } from "react";

import styles from '../../candidateInfo.module.scss';

import profileIcon from '../../../../../assets/icons/icon_photo.svg';
import pencilIcon from '../../../../../assets/icons/orange_pencil.svg';
import arrowDownIcon from '../../../../../assets/icons/arrow_down.svg';

import { getCatalog } from "../../../../api/catalogClient";

const PersonalInfo = ({
    personalInfo,
    setPersonalInfo,
    personalInfoErrors,
    file,
    setFile,
    descriptionFocus,
    photoError
}) => {

    const hiddenFileInput = useRef(null);
    const textareaRef = useRef(null);
    const photoRef = useRef(null);
    const [countrySelectFlag, setCountrySelectFlag] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCatalog(1000020016, 0 ,200, '&sort=id&direction=ASC').then((response) => {
            setCountries(response.data.content);
        }).catch((response) => {
            console.log(response);
        })
    }, [])

    const handleFormChange = ({ target }) => {
        setPersonalInfo((prevData) => ({...prevData, [target.name]: target.value}))
    }

    const handleFileInputChange = ({ target }) => {
        const fileUploaded = target.files[0];
        setFile(fileUploaded);
    }

    const handlePictureClick = () => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {
        focusTextarea();
        focusPhotoArea()
    })

    const focusTextarea = () => {
        if ( descriptionFocus ) {
            textareaRef.current.focus();
        }
    }
    
    const focusPhotoArea = () => {
        if ( photoError ) {
            photoRef.current.focus();
        }
    }

    return (
        <div className={ styles.form_container }>
            <p className={ styles.title }>Información Personal</p>
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_row} style={{marginBottom: '24px'}}>
                    <label>Nombres y apellidos como aparecen en tu identificación oficial.</label>
                </div>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <label>Nombres*</label>
                        <input className={ Object.keys(personalInfoErrors).includes("firstName") ? styles.error : undefined } 
                            name="firstName" type="text" value={ personalInfo.firstName || "" } onChange={ (event) => handleFormChange(event) } />
                        {
                            Object.keys(personalInfoErrors).includes("firstName") && (
                                <p className={ styles.error_message }>{ personalInfoErrors.firstName[0] }</p>
                            )
                        }
                    </div>
                    <div className={ styles.form_column }>
                        <label>Apellidos*</label>
                        <input className={ Object.keys(personalInfoErrors).includes("lastName") ? styles.error : undefined }
                            name="lastName" type="text" value={ personalInfo.lastName || "" } onChange={ (event) => handleFormChange(event) } />
                        {
                            Object.keys(personalInfoErrors).includes("lastName") && (
                                <p className={ styles.error_message }>{ personalInfoErrors.lastName[0] }</p>
                            )
                        }
                    </div>
                </div>
                <div className={ styles.form_row} style={{marginBottom: '12px'}}>
                    <label>Posición laboral a la que te estás postulando o última posición desempeñada*</label>
                </div>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <label className={ styles.subtitle }>
                            Ejemplos: Project Manager, Contador.
                        </label>
                        <input className={ Object.keys(personalInfoErrors).includes("position") ? styles.error : undefined }
                            name="position" type="text" value={ personalInfo.position || "" } onChange={ (event) => handleFormChange(event) } />
                        {
                            Object.keys(personalInfoErrors).includes("position") && (
                                <p className={ styles.error_message }>{ personalInfoErrors.position[0] }</p>
                            )
                        }
                    </div>
                </div>
                <div className={ styles.form_row}>
                    <label htmlFor="">Acerca de ti*</label>
                    <label className={ styles.subtitle }>
                        Danos un breve resumen de tu formación académica, experiencia profesional, y competencias técnicas y personales que te identifican.
                    </label>
                    <div className={  styles.text_area_container + " " + ((personalInfo.description?.length === 1500 || Object.keys(personalInfoErrors).includes("description")) && styles.error) }>
                        <textarea ref={ textareaRef } name="description" id="" cols="30" rows="10" maxLength="1500"
                            value={ personalInfo.description || "" } onChange={ (event) => handleFormChange(event) }>
                        </textarea>
                        <div className={ styles.text_lenght_container + " " + ((personalInfo.description?.length === 1500 || Object.keys(personalInfoErrors).includes("description")) && styles.error) }>
                            {
                                personalInfo.description?.length === 1500 && (
                                    <p className={ styles.error_message }>Has alcanzado el límite de caracteres permitido.</p>
                                )
                            }
                            {
                                Object.keys(personalInfoErrors).includes("description") && (
                                    <p className={ styles.error_message }>{ personalInfoErrors.description[0] }</p>
                                )
                            }
                            <p className={ personalInfo.description?.length === 1500 ? styles.error : undefined }>{ (personalInfo.description) ? personalInfo.description.length : 0 }/1500</p>
                        </div>
                    </div>
                </div>
                <p className={ styles.form_section_title }>Fotografía*</p>
                <p><b>Debe ser una fotografía reciente, a color, con fondo liso y en la que uses vestimenta casual de negocios.</b></p>
                <label className={ styles.subtitle }>
                    El tamaño máximo de 10 MB y estar en cualquiera de los siguientes formatos: JPG, JPEG o PNG.
                </label>
                {
                    Object.keys(personalInfoErrors).includes("imgProfile") && (
                        <p className={ styles.error_message }>{ personalInfoErrors.imgProfile[0] }</p>
                    )
                }
                <div className={ styles.form_row }>
                    <div className={ styles.image_container + " " + (( Object.keys(personalInfoErrors).includes("imgProfile")) && styles.error) } onClick={handlePictureClick}>
                        <button ref={ photoRef }>
                            {
                                !file ? (
                                    personalInfo.imgProfile ? (
                                        <>
                                        <div className={ styles.image_circle }>
                                            <img src={ `${personalInfo.imgProfile}?${performance.now()}` } />
                                        </div>
                                        <div className={ styles.image_text }>
                                            <p>Editar foto de perfil</p>
                                            <img src={ pencilIcon } alt="" />
                                        </div>
                                    </>
                                    ) : (
                                        <img src={ profileIcon } alt="" />
                                    )
                                ) : (
                                    <>
                                        <div className={ styles.image_circle }>
                                            <img src={ URL.createObjectURL(file) } />
                                        </div>
                                        <div className={ styles.image_text }>
                                            <p>Editar foto de perfil</p>
                                            <img src={ pencilIcon } alt="" />
                                        </div>
                                    </>
                                )
                            }
                            
                        </button>
                    </div>
                    <input className={ styles.file_input } type="file" onChange={handleFileInputChange} ref={hiddenFileInput} accept="image/png, image/gif, image/jpeg" />
                </div>
                <p className={ styles.form_section_title }>Contacto</p>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <label>Teléfono (10 dígitos)*</label>
                        <input className={ Object.keys(personalInfoErrors).includes("phone") ? styles.error : undefined }
                            name="phone" type="text" maxLength="10" value={ personalInfo.phone || "" } onChange={ (event) => handleFormChange(event) } />
                        {
                            Object.keys(personalInfoErrors).includes("phone") && (
                                <p className={ styles.error_message }>{ personalInfoErrors.phone[0] }</p>
                            )
                        }
                    </div>
                </div>
                <p className={ styles.form_section_title + " " + styles.no_padding_top }>Ubicación</p>
                <div className={ styles.form_columns }>
                    <div className={ styles.form_column }>
                        <label>País*</label>
                        <button className={ Object.keys(personalInfoErrors).includes("country") ? styles.error : undefined } onClick={() => setCountrySelectFlag(!countrySelectFlag)}>
                            {
                                personalInfo.country ? (
                                    <p>{  personalInfo.country }</p>
                                ) : (
                                    <p className={ styles.placeholder_text }>Selecciona un país</p>
                                )
                            }
                            
                            <img src={ arrowDownIcon } alt="" />
                            <div className={ styles.countries_list + " " + (countrySelectFlag ? styles.active: undefined) } >
                                {
                                    countries.map((countryInfo) => (
                                        <div key={ countryInfo.catalogItemId } className={ styles.country_container } onClick={ (event) => setPersonalInfo((prevData) => ({ ...prevData, country: countryInfo.valueItem })) }>
                                            <p>&#8226;</p>
                                            <p>{ countryInfo.valueItem }</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </button>
                        {
                            Object.keys(personalInfoErrors).includes("country") && (
                                <p className={ styles.error_message }>{ personalInfoErrors.country[0] }</p>
                            )
                        }
                    </div>
                    <div className={ styles.form_column }>
                        <label>Estado/Región*</label>
                        <input className={ Object.keys(personalInfoErrors).includes("state") ? styles.error : undefined }
                            name="state" type="text" value={ personalInfo.state || "" } onChange={ (event) => handleFormChange(event) }/>
                        {
                            Object.keys(personalInfoErrors).includes("state") && (
                                <p className={ styles.error_message }>{ personalInfoErrors.state[0] }</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalInfo;