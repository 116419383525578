import { 
    useContext,
    useEffect,
    useState
} from 'react';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import pencilIcon from '../../../../../assets/icons/blue_pencil.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';

import { AppContext } from '../../../../../router/approuter';
import { CandidateContext } from '../../context';
import { WorkExperienceModal } from './work_experience_modal';
import { objEquals, orderWorkExperiences, setStartAndEndDates } from '../../../../core/helper';
import { selectEmtpy, yearsHardCode, yearsOrderDesc } from '../../../../core/hardcode';
import { ModalDelete } from '../../../../components/modal/modal_delete';
import { getPreviousJobs, deletePreviouJob } from '../../../../api/previousJobClient';
import { getCatalog } from '../../../../api/catalogClient';

const WorkExperience = ({
    resumeViewFlag,
    renderBackButton
}) => {

    const appContext = useContext(AppContext);

    const [catSector, setCatSector] = useState([]);

    const {
        setWorkExperience,
        cleanWorkExperience,
        workExperiences, 
        setWorkExperiences 
    } = useContext( CandidateContext );

    const loadWorkExperiences = (catSector) => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getPreviousJobs(appContext.userData.userProfile.talentId).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                let workExperiencesTmp = [];
                response.data.result.map( workExperience => {
                    const weWithDates = setSelectDates(workExperience);
                    weWithDates.period = buildPeriodStr(weWithDates);
                    const sector = catSector.find(s => s.id === workExperience.sectorId)
                    weWithDates.sector = sector && sector.id ? { id: sector.id, description: sector.optionName } : selectEmtpy
                    workExperiencesTmp.push(weWithDates);
                })
                const workExperiencesCurrents = workExperiencesTmp.filter( we => /*!we.endDate*/ we.stillWork );
                const workExperiencesLast = workExperiencesTmp.filter( we => /*we.endDates !== null*/ !we.stillWork );
                workExperiencesTmp = [ ...orderWorkExperiences(workExperiencesCurrents) ]
                workExperiencesTmp = [ ...workExperiencesTmp, ...orderWorkExperiences(workExperiencesLast) ]
                setWorkExperiences(workExperiencesTmp);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch(() => {
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const deleteWorkExperience = workExperience => {
        deletePreviouJob(appContext.userData.userProfile.talentId, workExperience.id).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                setWorkExperiences( workExperiences.filter(we => !objEquals(we, workExperience) ) )
            }
        }).catch(() => {

        })
    }

    const setSelectDates = we => {
        const yearHardCode = yearsHardCode(yearsOrderDesc);
        return setStartAndEndDates(we, yearHardCode, yearHardCode);
    }

    const buildPeriodStr = workExperience => {
        const endDateStr = workExperience.stillWork ? 'Actualidad' : `${workExperience.ftMes.description} ${workExperience.ftAnio.description}`;
        return `${workExperience.fiMes.description} ${workExperience.fiAnio.description} - ${endDateStr}`;
    }

    const handleOnClickShowModal = (workExperience, index) => {
        if ( workExperience ) {
            setWorkExperience({
                ...workExperience,
                index: index
            });
        } else if (!workExperience) {
            cleanWorkExperience();
        }
        appContext.setModalContent( <WorkExperienceModal catSector={catSector} /> )
        appContext.setModalFull(true)
        appContext.setModalFlag(true)
    }

    const handleOnClickRemove = workExperience => {
        if( workExperience.id ) {
            deleteWorkExperience(workExperience);
        } else {
            setWorkExperiences( workExperiences.filter(we => !objEquals(we, workExperience)) )
        }
        appContext.setModalFlag(false);
    }

    useEffect(() => {
        getCatalog(1000020052, 0 ,100, '').then((response) => {
            const sectorTmp = [];
            response.data.content.map( s => sectorTmp.push({ id: s.catalogItemId, optionName: s.valueItem }) )
            setCatSector(sectorTmp);
            loadWorkExperiences(sectorTmp);
        }).catch((response) => {
            console.log(response);
        });
    }, []);

    useEffect(() => {
        setWorkExperiences(orderWorkExperiences(workExperiences));
    }, [workExperiences])

    const showDeleteModal = index => {
        const workExperience = workExperiences[index];
        appContext.setModalContent(
            <ModalDelete
                title={ `${workExperience.position} - ${workExperience.employer}` }
                subtitle={`¿Quieres borrar esta experiencia laboral de tu perfil?
                    Esta acción no podrá revertirse.`}
                onClick={ () => handleOnClickRemove(workExperience) }
                onClickCancel={ () => appContext.setModalFlag(false) }
            />
        )
        appContext.setModalFull(false);
        appContext.setModalFlag(true);
    }

    const renderWorkExperiences = () => (
		workExperiences && workExperiences.length > 0 && workExperiences.map((we, index) => {
            return (
                <div key={index} className={ `${styles.list_container} ${ index === 0 ? styles.top_padded : '' }` }>
                    {/* index: {index} id: {we.id} */}
                    <div className={ styles.list_items }>
                        <div className={ styles.item_grouped }>
                            <label>{ `${we.position} - ${we.employer} - Sector: ${we.sectorName || "No proporcionado"}` }</label>
                            <div className={ styles.action_container }>
                                <img src={ pencilIcon } alt="" onClick={ () => handleOnClickShowModal(we, index) } />
                                <img src={ trashIcon } alt="" onClick={ () => showDeleteModal(index) } />
                            </div>
                        </div>
                        <label className={ styles.subtitle }>{ we.period }</label>
                        <p>{ we.description ? we.description : 'No describiste actividades realizadas en este puesto.' }</p>
                    </div>
                </div>
            )}
        )
	);

    const title = 'Experiencia Profesional';
    const renderTitle = () => (<p className={ styles.title }>{ title }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(title)) }
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_row + " " + styles.no_gap }>
                    <p className={ styles.subtitle }>
                        Agrega información de tu experiencia profesional. Puedes incluir práticas profesionales. 
                    </p>
                    {/* {<p className={ styles.subtitle }>
                        En caso de no contar con ninguna da click en “Omitir”.
                    </p>} */}
                </div>
                {
                    renderWorkExperiences()
                }
                <div className={ styles.form_row + " " + styles.padded }>
                    <button className={ styles.add_info } onClick={() => handleOnClickShowModal(null)}>
                        Agregar experiencia profesional
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default WorkExperience;