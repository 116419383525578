import Axios from 'axios';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;

const mainContext = 'talent';
const module = 'course';

export const newCourse = (talentId, workExperience) => Axios.post(`${apiUrl}${mainContext}/${talentId}/${module}/new`, { ...workExperience, description: 'No se proporciono descripción' });

export const editCourse = (talentId, workExperience) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/edit`, workExperience);

export const getCourses = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`);

export const deleteCourse = (talentId, id) => Axios.delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete`);