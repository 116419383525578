import Axios from 'axios';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;

const mainContext = 'talent';
const module = 'skills';

const headerLanguage = {
    headers: {
        "Accept-Language": "es-MX"
    }
};

export const newSkill = (talentId, workExperience) => Axios.post(`${apiUrl}${mainContext}/${talentId}/${module}/new`, workExperience);

export const editSkill = (talentId, workExperience) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/edit`, workExperience);

export const getSkills = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`, headerLanguage);

export const deleteSkill = (talentId, id) => Axios.delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete`);

export const saveAll = (talentId, request) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/save`, request, headerLanguage);