import {
    useContext } from 'react';
import Axios  from 'axios';

import { AppContext } from '../../router/approuter';

function RequestFactory() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const appContext = useContext(AppContext);

    const instance = Axios.create({
        baseURL: apiUrl,
        // headers: {
        //     Authorization: `Bearer ${appContext.userInfo.token}`
        // }
    })

    instance.interceptors.response.use(response => response, error => {
        if (error) {
            if (error.response.status === 401) {
                appContext.navigate("/login")
            }
            return Promise.reject(error);
        }
	});

    return instance
}
export default RequestFactory