import { 
    useContext,
    useEffect,
    useState
} from 'react';
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import pencilIcon from '../../../../../assets/icons/blue_pencil.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';

import { AppContext } from '../../../../../router/approuter';
import { CandidateContext } from '../../context';
import { MONTHS, selectEmtpy, yearsHardCode, yearsOrderDesc } from '../../../../core/hardcode';
import { objEquals, serializeSelectValue, sorterDate, unsearializaDateString } from '../../../../core/helper';
import { AcademicInfoModal } from './academic_info_modal';
import { ModalDelete } from '../../../../components/modal/modal_delete';
import { deleteEducation, getEducations } from '../../../../api/educationClient';
import { getCatalog } from '../../../../api/catalogClient';

const AcademicInfo = ({
    resumeViewFlag,
    renderBackButton
}) => {

    const appContext = useContext(AppContext)

    const [catStudyLevel, setCatStudyLevel] = useState([]);

    const {
        setAcademic,
        cleanAcademic,
        academics, 
        setAcademics 
    } = useContext( CandidateContext );

    const getAcademics = catStudyLevel => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getEducations(appContext.userData.userProfile.talentId).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                let academicsTmp = [];
                response.data.result.map( academic => {
                    const academicWithDates = setSelectDates(academic);
                    academicWithDates.startDateParsed =  `${academicWithDates.fIniMes.description} ${academicWithDates.fIniAnio.description}`
                    academicWithDates.endDateParsed =  (academicWithDates.endDate ? `${academicWithDates.fTerMes.description} ${academicWithDates.fTerAnio.description}` : "En curso")
                    academicWithDates.period = academicWithDates.stillStudy ? 'Estudiante' : `Fecha de graduación: ${academicWithDates.fIniMes.description} ${academicWithDates.fIniAnio.description}`;
                    const studyLevel = catStudyLevel.find(s => s.id === academic.typeId)
                    academicWithDates.type = studyLevel && studyLevel.id ? { id: studyLevel.id, description: studyLevel.optionName } : selectEmtpy
                    academicsTmp.push(academicWithDates);
                })
                setAcademics(academicsTmp.sort((a, b) => sorterDate(a.fIniAnio.id, a.fIniMes.id, b.fIniAnio.id , b.fIniMes.id, yearsOrderDesc)));
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch((resp) => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(resp);
        })
    }

    const deleteAcademic = academic => {
        deleteEducation(appContext.userData.userProfile.talentId, academic.courseId).then((response) => {
            if( response.data && response.data.code === 201 && response.data.result ) {
                setAcademics( academics.filter(ac => !objEquals(ac, academic) ) )
            }
        }).catch(() => {

        })
    }

    const setSelectDates = academic => {
        const academicTmp = { ...academic };
        if ( academic.endDate ) {
            const yearHardCode = yearsHardCode(yearsOrderDesc);
            const endDate = unsearializaDateString(academicTmp.endDate);
            const fTerMes = MONTHS.find( m => m.id === endDate.monthId )
            const fTerAnio = yearHardCode.find( y => y.optionName === endDate.yearDesc)
            academicTmp.fTerMes = serializeSelectValue(fTerMes);
            academicTmp.fTerAnio = serializeSelectValue(fTerAnio);
            academicTmp.stillStudy = false;
        } else {
            academicTmp.fTerMes = selectEmtpy;
            academicTmp.fTerAnio = selectEmtpy;
            academicTmp.stillStudy = true;
        }

        if ( academic.startDate ) {
            const yearHardCode = yearsHardCode(yearsOrderDesc);
            const startDate = unsearializaDateString(academicTmp.startDate);
            const fIniMes = MONTHS.find( m => m.id === startDate.monthId )
            const fIniAnio = yearHardCode.find( y => y.optionName === startDate.yearDesc)
            academicTmp.fIniMes = serializeSelectValue(fIniMes);
            academicTmp.fIniAnio = serializeSelectValue(fIniAnio);
            academicTmp.stillStudy = false;
        } else {
            academicTmp.fIniMes = selectEmtpy;
            academicTmp.fIniAnio = selectEmtpy;
        }

        return academicTmp;
    }

    const handleOnClickShowModal = (academic, index) => {
        if ( academic ) {
            setAcademic({
                ...academic,
                index: index
            });
        } else if (!academic) {
            cleanAcademic();
        }
        appContext.setModalContent( <AcademicInfoModal catStudyLevel={ catStudyLevel } /> )
        appContext.setModalFull(true)
        showModal(true)
    }

    const handleOnClickRemove = academic => {
        if( academic.courseId ) {
            deleteAcademic(academic);
        } else {
            setAcademics( academics.filter(ac => !objEquals(ac, academic)) )
        }
        showModal(false);
    }

    const showModal = isShow => {
        appContext.setModalFlag(isShow);
    }

    useEffect(() => {
        getCatalog(1000010019, 0 ,100, '').then((response) => {
            const catStudyLevel = [];
            response.data.content.map( s => catStudyLevel.push({ id: s.catalogItemId, optionName: s.valueItem }) )
            setCatStudyLevel(catStudyLevel);
            getAcademics(catStudyLevel)
        }).catch((response) => {
            console.log(response);
        });
    }, []);

    useEffect(() => {
        setAcademics(academics.sort((a, b) => sorterDate(a.fIniAnio.id, a.fIniMes.id, b.fIniAnio.id , b.fIniMes.id, yearsOrderDesc)));
    }, [academics])

    const showDeleteModal = index => {
        const academic = academics[index];
        appContext.setModalContent(
            <ModalDelete
                title={ `${academic.name} - ${academic.school}` }
                subtitle={`¿Quieres borrar esta formación académica de tu perfil?
                    Esta acción no podrá revertirse.`}
                onClick={ () => handleOnClickRemove(academic) }
                onClickCancel={ () => showModal(false) }
            />
        )
        appContext.setModalFull(false);
        showModal(true);
    }

    const renderAcademics = () => (
		academics && academics.length > 0 && academics.map((ac, index) => (
            <div key={index} className={ `${styles.list_container} ${ index === 0 ? styles.top_padded : '' }` }>
                <div className={ styles.list_items }>
                    <div className={ styles.item_grouped }>
                        <label>{ `${ac.name} - ${ac.school}` }</label>
                        <div className={ styles.action_container }>
                            <img src={ pencilIcon } alt="" onClick={ () => handleOnClickShowModal(ac, index) } />
                            <img src={ trashIcon } alt="" onClick={ () => showDeleteModal(index) } />
                        </div>
                    </div>
                    <div className={ styles.certification_info }>
                        <div className={ styles.info_container}>
                            <label>Periodo:</label>
                            <p>{ `${ac.startDateParsed} - ${ac.endDateParsed}` }</p>
                        </div>
                    </div>
                </div>
            </div>
        ))
	);

    const title = 'Formación Académica';
    const renderTitle = () => (<p className={ styles.title }>{ title }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(title)) }
            <div className={ styles.form_wrapper }>
                <div className={ styles.form_row + " " + styles.no_gap }>
                    <p className={ styles.subtitle }>
                        Agrega informaci&oacute;n de tu formaci&oacute;n acad&eacute;mica. 
                    </p>
                </div>
                {
                    renderAcademics()
                }
                <div className={ styles.form_row + " " + styles.padded }>
                    <button className={ styles.add_info } onClick={() => handleOnClickShowModal(null)}>
                        {
                            academics?.length === 0 ? (
                                "Comienza a agregar tu formación académica"
                            ) : (
                                "Agregar formación académica"
                            )
                        }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default AcademicInfo;