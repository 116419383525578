import Axios from 'axios';

const apiUrl = process.env.REACT_APP_CATALOG_API;

export const getCatalog = (catalogId, page ,size, sort) => Axios.get(`${apiUrl}${catalogId}/catalogItem/list?page=${page}&size=${size}${sort}`);

export const getSkillsCatalog = (page ,size) => Axios.get(`${apiUrl}candidates/skills/?page=${page}&size=${size}&sort=name&direction=ASC`,{'Accept-Language': 'es-MX'});

export const getLanguageCatalog = () => Axios.get(`${apiUrl}language/`,{'Accept-Language': 'es-MX'});

export const getLangLevelCatalog = () => Axios.get(`${apiUrl}language/level`,{'Accept-Language': 'es-MX'});
