import { 
    useState,
    useRef } from 'react';
import styles from "../password_recovery.module.scss"
import stylesCandidate from '../../candidates/candidateInfo.module.scss';

import Axios from 'axios';

import ReCAPTCHA from "react-google-recaptcha";

import { 
    validateEmail,
    validateNotNull } from "../../../core/validators";

import recoveryImage from '../../../../assets/img/change_pswd.png'

const RecoveryForm = (props) => {

    const recaptcha = useRef();
    const apiUrl = process.env.REACT_APP_API_LOGIN_URL;
    const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;
    const { recoveryInfo, setRecoveryInfo, setStepIndex, appContext } = props;
    const [formErrors, setFormErrors] = useState(false)

    const validationFields = {
        username: [validateEmail, validateNotNull]
    }

    const handleFormChange = ({target}) => {
        setRecoveryInfo((prevData) => ({...prevData, [target.name]: target.value}))
    }

    const validateRequest = () => {
        const errorObject = {};
        var helper;
        // validating fields
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](recoveryInfo[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        return errorObject
    }

    const handleSubmit = event => {
        event.preventDefault();
        const validationErrors = validateRequest();
        // validando captcha
        if (!recaptcha.current.getValue()) {
            validationErrors.captcha = ["Por favor, marca la casilla para continuar"]
        }
        if(Object.keys(validationErrors).length === 0) {
            appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
            Axios.get(`${apiUrl}resetPassword/?username=${recoveryInfo.username}`).then((response) => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if (response.data.code === 151) {
                    appContext.setModalFlag(true);
                    setStepIndex(1);
                    return
                }
                appContext.displayNotification("Código de verificación enviado");
                setStepIndex(1);
            }).catch(error => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if( error.code === 'ERR_NETWORK' ) {
                    appContext.displayNotification(null, true, true);
                }
            }) 
        } else {
            setFormErrors(validationErrors)
        }
    }

    return (
        <div className={ styles.recovery_form_wrapper }>
            <p className={ styles.title }>Cambia tu contrase&ntilde;a</p>
            <div className={ styles.instructions}>
                <p>Para cambiar tu contrase&ntilde;a, solo ingresa la direcci&oacute;n de correo asociada a tu cuenta, y en breve, te enviaremos un correo electr&oacute;nico con las instrucciones necesarias para continuar.</p>
            </div>
            <div className={ styles.form_wrapper }>
                <div className={ styles.data_wrapper }>
                    <form className={ styles.password_recovery } onChange={ (event) => handleFormChange(event) }>
                        <div className={ styles.form_field }>
                            <label>Correo electr&oacute;nico*</label>
                            <input className={Object.keys(formErrors).includes("username") ? styles.error : undefined} type="text" name="username" />
                            {
                                Object.keys(formErrors).includes("username") && (
                                    <p className={ styles.error_message }>{ formErrors.username[0] }</p>
                                )
                            }
                        </div>
                        <div className={ styles.centered_field }>
                            <ReCAPTCHA
                                sitekey={ captchaKey }
                                ref={ recaptcha }
                            />
                            {
                                Object.keys(formErrors).includes("captcha") && (
                                    <p className={ styles.error_message }>{ formErrors.captcha[0] }</p>
                                )
                            }
                        </div>
                        <div className={styles.recovery_button }>
                            <button className={ styles.active } type="submit " onClick={(event) => handleSubmit(event) }>Enviar c&oacute;digo de verificaci&oacute;n</button>
                        </div>
                        <hr />
                        <div className={ styles.centered_field }>
                            <p>Si no quieres cambiar tu contrase&ntilde;a</p>
                            <a href="/login">Inicia sesi&oacute;n aqu&iacute;</a>
                        </div>
                    </form>
                </div>
                {
                    appContext.isDesktopOrLaptop && (
                        <div className={ styles.image_column }>
                            <img src={ recoveryImage } alt="" />
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default RecoveryForm