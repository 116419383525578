import { useContext, useEffect, useState } from 'react';

import styles from './candidateInfo.module.scss';

import addPictureIcon from '../../../assets/icons/add_photo.svg';
import editIcon from '../../../assets/icons/orange_pencil.svg';

import envelopeIcon from '../../../assets/icons/envelope.svg';
import locationIcon from '../../../assets/icons/location.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import { getTalent } from '../../api/talentClient';
import { AppContext } from '../../../router/approuter';
import { unsearializaDateString } from '../../core/helper';
import { MONTHS } from '../../core/hardcode';
import { CandidateContext } from './context';

const CandidateResume = (
    {
        setStepIndex,
        setResumeViewFlag,
        careersRedirect,
        displayNotification
    }
) => {

    const appContext = useContext(AppContext);
    const { setPersonalInfoCache } = useContext(CandidateContext);
    const [personalInfo, setPersonalInfo] = useState({})
    const courseTypes = {
        1001900001: "Certificación",
        1001900006: "Curso",
        1001900002: "Diplomado"
    }

    const getUserInfo = () => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getTalent(appContext.userData.userProfile.talentId).then((response) => {
            const personalInfo = {
                firstName: response.data.result.firstName,
                lastName: response.data.result.lastName,
                position: response.data.result.position,
                description: response.data.result.description,
                phone: response.data.result.phone,
                email: response.data.result.email,
                country: response.data.result.address?.country,
                state: response.data.result.address?.state,
                imgProfile: response.data.result.imgProfile,
                previousJobs: response.data.result.previousJobs,
                educations: response.data.result.educations,
                courses: response.data.result.courses,
                languages: response.data.result.languages,
                skills: response.data.result.skills
            };
            setPersonalInfo(personalInfo);
            setPersonalInfoCache(personalInfo)
            appContext.showLoading(false, '', styles.no_scroll);
        }).catch( error => {
            console.log(error);
            if( error.code === 'ERR_NETWORK' ) {
                displayNotification(null, false, false, true);
            }
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    useEffect(() => {
        setResumeViewFlag(true);
        getUserInfo();
    }, [])


    const updateView = (index) => {
        window.scrollTo(0, 0);
        setStepIndex(index);
    }

    const getParsedDate = (dateString) => {
        // const date = new Date(dateString);
        // return `${date.toLocaleString('es-ES', { month: 'long' }).charAt(0).toUpperCase()}${date.toLocaleString('es-ES', { month: 'long' }).slice(1)} ${date.getFullYear()}`
        const dateJson = unsearializaDateString(dateString);
        const fMes = MONTHS.find( m => m.id === dateJson.monthId )
        return `${fMes.optionName} ${dateJson.yearDesc}`;
    }


    return (
        <div className={ styles.candidate_resume }>
            <p className={ styles.title }>Revisa tu perfil</p>
            <hr className={ styles.mobile_separator } />
            <div className={ styles.content_wrapper }>
                <p className={ styles.section_subtitle }>
                    Antes de enviar tu perfil, revisa que todo est&eacute; en orden. Una vez que termines, 
                    da click en “Enviar perfil”.
                </p>

                <div className={ styles.personal_info }>
                    <div className={ styles.section_header}>
                        <div className={ styles.section_title }>
                            <div className={ styles.decorator }></div>
                            Informaci&oacute;n Personal
                        </div>
                        <div className={ styles.button_container }>
                            <div className={ styles.vertical_separator }></div>
                            <button className={ styles.add_info } onClick={() => updateView(0) }>
                                Editar
                                <img src={ editIcon } alt="" />
                            </button>
                        </div>
                    </div>
                    <div className={ styles.personal_info_wrapper }>
                        <div className={styles.profile_info }>
                            <div className={ styles.picture_column}>
                                {
                                    personalInfo.imgProfile ? (
                                        <div className={ styles.candidate_profile_picture }>
                                            <img src={ `${personalInfo.imgProfile}?${performance.now()}` } alt="" />
                                        </div>
                                    ) : (
                                        <button onClick={() => updateView(0) }>
                                            <img src={ addPictureIcon } alt="" />
                                        </button>
                                    )
                                }
                            </div>
                            <div className={ styles.candidate_info_columns }>
                                <p className={ styles.candidate_name }>{ personalInfo.firstName + " " + personalInfo.lastName }</p>
                                <p className={ styles.candida_position}>{ personalInfo.position }</p>
                                <div className={ styles.candidate_contact_info }>
                                    <div className={ styles.contact_field }>
                                        <img src={ envelopeIcon } alt="" />
                                        <p>{ personalInfo.email }</p>
                                    </div>
                                    <div className={ styles.personal_info_separator }></div>
                                    <div className={ styles.contact_field }>
                                        <img src={ phoneIcon } alt="" />
                                        <p>{ personalInfo.phone || "No proporcionado" }</p>
                                    </div>
                                    <div className={ styles.personal_info_separator }></div>
                                    <div className={ styles.contact_field }>
                                        <img src={ locationIcon } alt="" />
                                        {
                                            (personalInfo.country && personalInfo.state) ? (
                                                <p>{ personalInfo.country + ", " + personalInfo.state  }</p>
                                            ) : (
                                                <p>No proporcionado</p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ styles.candidate_contact_info_mobile }>
                            <div className={ styles.contact_field }>
                                <img src={ envelopeIcon } alt="" />
                                <p>{ personalInfo.email }</p>
                            </div>
                            <div className={ styles.personal_info_separator }></div>
                            <div className={ styles.contact_field }>
                                <img src={ phoneIcon } alt="" />
                                <p>{ personalInfo.phone || "No proporcionado" }</p>
                            </div>
                            <div className={ styles.personal_info_separator }></div>
                            <div className={ styles.contact_field }>
                                <img src={ locationIcon } alt="" />
                                {
                                    (personalInfo.country && personalInfo.state) ? (
                                        <p>{ personalInfo.country + ", " + personalInfo.state  }</p>
                                    ) : (
                                        <p>No proporcionado</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className={ styles.about_candidate}>
                            <label htmlFor="">Acerca de ti</label>
                            <p>{ personalInfo.description }</p>
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    <div className={ styles.section }>
                        <div className={ styles.section_header}>
                            <div className={ styles.section_title }>
                                <div className={ styles.decorator }></div>
                                Experiencia Profesional
                            </div>
                            <div className={ styles.button_container }>
                                <div className={ styles.vertical_separator }></div>
                                <button className={ styles.add_info } onClick={() => updateView(1)}>
                                    Editar
                                    <img src={ editIcon } alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={ styles.cards_wrapper}>
                            {
                                personalInfo.previousJobs?.map((jobInfo, index) => {
                                    return (
                                        <div key={ index } className={ styles.info_card }>
                                            <div className={ styles.card_title}>
                                                <label>{ jobInfo.position }</label>
                                                <label className={ styles.hidden_mobile }>-</label>
                                                <label>{(jobInfo.employer || "No proporcionado") }</label>
                                                <label className={ styles.hidden_mobile }>-</label>
                                                <label>Sector: {(jobInfo.sectorName || "No proporcionado") }</label>
                                            </div>
                                            <p className={ styles.info_date }>{ `${jobInfo.startDate ? getParsedDate(jobInfo.startDate) : "No proporcionado" } - ${(jobInfo.endDate ? getParsedDate(jobInfo.endDate) : "Actualidad" )}`  }</p>
                                            <p className={ styles.info_description}>{ jobInfo.description || "No describiste actividades realizadas en este puesto" }</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    <div className={ styles.section }>
                        <div className={ styles.section_header}>
                            <div className={ styles.section_title }>
                                <div className={ styles.decorator }></div>
                                Formaci&oacute;n Acad&eacute;mica
                            </div>
                            <div className={ styles.button_container }>
                                <div className={ styles.vertical_separator }></div>
                                <button className={ styles.add_info } onClick={() => updateView(2)}>
                                    Editar
                                    <img src={ editIcon } alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={ styles.cards_wrapper }>
                            {
                                personalInfo.educations?.map((educationInfo, index) => {
                                    return(
                                        <div key={ index } className={ styles.info_card }>
                                            <div className={ styles.card_title}>
                                                <label>{ educationInfo.name }</label>
                                                <label className={ styles.hidden_mobile }>-</label>
                                                <label>{(educationInfo.school || "No proporcionado") }</label>
                                            </div>
                                            <p className={ styles.info_date }>{ `${(educationInfo.startDate)  ? getParsedDate(educationInfo.startDate) : "No proporcionado" } - ${(educationInfo.endDate ? getParsedDate(educationInfo.endDate) : "En curso" )}`  }</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    <div className={ styles.section }>
                        <div className={ styles.section_header}>
                            <div className={ styles.section_title }>
                                <div className={ styles.decorator }></div>
                                Certificaciones y Cursos
                            </div>
                            <div className={ styles.button_container }>
                                <div className={ styles.vertical_separator }></div>
                                <button className={ styles.add_info } onClick={() => updateView(3)}>
                                    Editar
                                    <img src={ editIcon } alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={ styles.cards_wrapper }>
                            {
                                personalInfo.courses?.map((courseInfo, index) => {
                                    return(
                                        <div key={ index } className={ styles.info_card }>
                                            <div className={ styles.card_title}>
                                                <label>{ courseTypes[courseInfo.typeId]  }</label>
                                                <label className={ styles.hidden_mobile }>-</label>
                                                <label>{ courseInfo.name }</label>
                                                <label className={ styles.hidden_mobile }>-</label>
                                                <label>{ courseInfo.school }</label>
                                            </div>
                                            <div>
                                                <p className={ styles.info_description}>Número de certificado: { courseInfo.certificateNumber || "Sin número de certificado" }</p>
                                                <p className={ styles.info_date }>Periodo: { `${ courseInfo.startDate ? getParsedDate(courseInfo.startDate) : "No proporcionado"} - ${(courseInfo.endDate ? getParsedDate(courseInfo.endDate) : "En curso" )}`  }</p>
                                                <p className={ styles.info_date }>Fecha de vigencia: { `${(courseInfo.validityDate ? getParsedDate(courseInfo.validityDate) : "Sin vigencia" )}`  }</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    <div className={ styles.section }>
                        <div className={ styles.section_header}>
                            <div className={ styles.section_title }>
                                <div className={ styles.decorator }></div>
                                Idiomas
                            </div>
                            <div className={ styles.button_container }>
                                <div className={ styles.vertical_separator }></div>
                                <button className={ styles.add_info } onClick={() => updateView(4)}>
                                    Editar
                                    <img src={ editIcon } alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={ styles.cards_wrapper }>
                            {
                                personalInfo.languages?.map((languageInfo, index) => {
                                    return(
                                        <div key={ index } className={ styles.info_card }>
                                            <div className={ styles.language_info }>
                                                <label >{`${languageInfo.languageName} ${languageInfo.langLevelName} - ${ languageInfo.langLevelDescription.split(":")[0] }: `} </label>
                                                <p>{ languageInfo.langLevelDescription.split(":")[1] }</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    <div className={ styles.section }>
                        <div className={ styles.section_header}>
                            <div className={ styles.section_title }>
                                <div className={ styles.decorator }></div>
                                Habilidades
                            </div>
                            <div className={ styles.button_container }>
                                <div className={ styles.vertical_separator }></div>
                                <button className={ styles.add_info } onClick={() => updateView(5)}>
                                    Editar
                                    <img src={ editIcon } alt="" />
                                </button>
                            </div>
                        </div>
                        <p className={ styles.section_title }>Competencias técnicas</p>
                        <div className={ styles.pills_wrapper }>
                            {
                                personalInfo.skills?.filter(s => s.categoryId === 1001100001).map((skillInfo, index) => (
                                    <p key={index}>{ `${skillInfo.name} (${ skillInfo.skillLevelPercentage }%)` }</p>
                                ))
                            }
                        </div>
                        <p className={ styles.section_title }>Competencias personales</p>
                        <div className={ styles.pills_wrapper }>
                            {
                                personalInfo.skills?.filter(s => s.categoryId === 1001100002).map((skillInfo, index) => (
                                    <p key={index}>{ `${skillInfo.name} (${ skillInfo.skillLevelPercentage }%)` }</p>
                                ))
                            }
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    <div className={ styles.section }>
                        <div className={ styles.section_header}>
                            <div className={ styles.section_title }>
                                <div className={ styles.decorator }></div>
                                Software
                            </div>
                            <div className={ styles.button_container }>
                                <div className={ styles.vertical_separator }></div>
                                <button className={ styles.add_info } onClick={() => updateView(6)}>
                                    Editar
                                    <img src={ editIcon } alt="" />
                                </button>
                            </div>
                        </div>
                        <div className={ styles.pills_wrapper }>
                            {
                                personalInfo.skills?.filter(s => s.categoryId === 1001100004).map((skillInfo, index) => (
                                    <p key={index}>{ `${skillInfo.name} (${ skillInfo.skillLevelPercentage }%)` }</p>
                                ))
                            }
                        </div>
                    </div>
                    <hr className={ styles.separator } />
                    {
                        !careersRedirect && (
                            <div className={ styles.buttons }>
                                <button onClick={() => updateView(9) }>Terminar Perfil</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default CandidateResume;