import Axios from 'axios';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;

const mainContext = 'talent';
const module = 'previous_job';

export const newPreviouJob = (talentId, workExperience) => Axios.post(`${apiUrl}${mainContext}/${talentId}/${module}/new`, workExperience);

export const editPreviouJob = (talentId, workExperience) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/edit`, workExperience);

export const getPreviousJobs = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`);

export const deletePreviouJob = (talentId, id) => Axios.delete(`${apiUrl}${mainContext}/${talentId}/${module}/${id}/delete`);