import { 
    useState } from 'react';

import styles from '../password_recovery.module.scss';
import stylesCandidate from '../../candidates/candidateInfo.module.scss';
import resendIcon from '../../../../assets/icons/arrows_circle_blue.svg';

import Axios from 'axios';

import {ReactComponent as HidePassIcon} from '../../../../assets/icons/hide_password.svg';
import {ReactComponent as ShowPassIcon} from '../../../../assets/icons/view_password.svg';
import {ReactComponent as ValidationIcon} from '../../../../assets/icons/check_gray.svg';

import { 
    validateMinLength,
    validateNotNull,
    validateLowerCharacter,
    validateUpperCase,
    validateNumber } from '../../../core/validators';


const VerificationCode = (props) => {

    const apiUrl = process.env.REACT_APP_API_LOGIN_URL;
    const passwordRecoveryNotificationApiURl = process.env.REACT_APP_API_URL;

    const { recoveryInfo, setStepIndex, handleCancelValidation, displayNotification, setRecoveryInfo, appContext } = props;
    const [validationErrors, setValidationErrors] = useState({});
    const [requestFlag, setRequestFlag] = useState(false);
    const [passwordVisibilityFlag, setPasswordVisibilityFlag] = useState(true);
    const [passwordConfirmationVisibilityFlag, setPasswordConfirmationVisibilityFlag] = useState(true);
    const [codeInfo, setCodeInfo] = useState({});

    const [showPswSug, setShowPswSug] = useState(false);
    const [showComPswSug, setShowComPswSug] = useState(false);

    const validationFields = {
        password: [validateLowerCharacter, validateUpperCase, validateNumber, validateMinLength, validateNotNull],
        passwordConfirmation: [validateNotNull]
    }

    const codeInputs = [
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code" key={1} name="0" maxLength="1" value={ codeInfo[0] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code1" key={2} name="1" maxLength="1" value={ codeInfo[1] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code11" key={3} name="2" maxLength="1" value={ codeInfo[2] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code111" key={4} name="3" maxLength="1" value={ codeInfo[3] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code1111" key={5} name="4" maxLength="1" value={ codeInfo[4] || "" }/>,
        <input className={ Object.keys(validationErrors).includes("code") ? styles.error : undefined  } id="code11111" key={6} name="5" maxLength="1" value={ codeInfo[5] || "" }/>
    ]

    const handleCodeChange = event => {
        if(event.nativeEvent.data === null) {
            setCodeInfo((prevData) => ({...prevData, [event.target.name]: ""}))
        }
        if(event.target.value.match(/^[0-9]$/)){
            setCodeInfo((prevData) => ({...prevData, [event.target.name]: event.target.value}))
            // actualizando el focus
            if (event.target.id !== "code11111") {
                document.getElementById(event.target.id + 1).focus();
            }
        }
    }

    const validateRequest = () => {
        const errorObject = {};
        // validate code inputs
        if(Object.keys(codeInfo).filter((index) => {
            if(codeInfo[index] !== "") {
                return codeInfo[index]
            }
        }).length < 6) {
            errorObject["code"] = ["Este campo es obligatorio"]
        };

        // validate password confirmation
        if((recoveryInfo.password && recoveryInfo.passwordConfirmation) && (recoveryInfo.password !== recoveryInfo.passwordConfirmation)) {
            errorObject["password"] = [""]
            errorObject["passwordConfirmation"] = ["Ambas contraseñas deben coincidir"]
        }

        // validate password inputs
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](recoveryInfo[field])
                if (helper) {
                    errorObject[field] = helper
                }
            }
        }
        return errorObject
    }

    const handleSubmit = () => {
        setRequestFlag(true);
        const validationErrors = validateRequest();
        if (Object.keys(validationErrors).length === 0) {
            const request = {
                "username": recoveryInfo.username,
                "code": Object.keys(codeInfo).map((index) => codeInfo[index]).join(''),
                "password": recoveryInfo.password
            }
            appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
            Axios.put(apiUrl + "updatePassword", request).then((response) => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if (response.data.code === 153) {
                    setValidationErrors({
                        code: ["Código de verificación incorrecto"]
                    })
                    return
                } 
                appContext.displayNotification("Contraseña actualizada correctamente");
                appContext.navigate("/login");
            }).catch(error => {
                appContext.showLoading(false, '', stylesCandidate.no_scroll);
                if( error.code === 'ERR_NETWORK' ) {
                    appContext.displayNotification(null, true, true);
                } else if(error.response.status === 500) {
                    setValidationErrors({
                        code: ["Código de verificación incorrecto"]
                    })
                }
            })
        } else {
            setValidationErrors(validationErrors);
        }
    }

    const handleResendCode = () => {
        appContext.showLoading(true, 'Cargando', stylesCandidate.no_scroll);
        Axios.get(`${passwordRecoveryNotificationApiURl}auth/signUp/resendVerifyCode?username=${recoveryInfo.username}`).then((response) => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if (response.data.code === 151) {
                appContext.setModalFlag(true);
                return
            } 
            appContext.displayNotification('Código de verificación enviado');
        }).catch(error => {
            appContext.showLoading(false, '', stylesCandidate.no_scroll);
            if( error.code === 'ERR_NETWORK' ) {
                appContext.displayNotification(null, true, true);
            } else if(error.response.status === 500 && (error.response.data.code === 150)) {
                appContext.setModalFlag(true);
            }
            console.log(error);
        })
    }

    const handleFormChange = ({target}) => {
        setRecoveryInfo((prevData) => ({...prevData, [target.name]: target.value}))
    }

    const showSuggestionModal = value => {
        return value && value.length > 0;
    }

    const renderSuggestions = (show, value, bottomValue) => {
        const bottomStyle = { bottom: bottomValue };
        return appContext.isDesktopOrLaptop && show && (
            <div className={ `${styles.password_validator} ${showSuggestionModal(value) ? styles.active : undefined}` } style={ bottomStyle }>
                <div className={ styles.validator_row }>
                    <p className={ styles.validator_title }>Tu contraseña debe tener:</p>
                </div>
                <div className={ styles.validator_row  +  " " + (!validateMinLength(value) ? styles.validated : (((validateMinLength(value) && requestFlag ) ?  styles.error : undefined )) )  }>
                    <ValidationIcon />
                    <p>Al menos 8 caractéres</p>
                </div> 
                <div className={ styles.validator_row +  " " + (!validateLowerCharacter(value) ? styles.validated : (((validateLowerCharacter(value) && requestFlag ) ?  styles.error : undefined )) ) }>
                    <ValidationIcon />
                    <p>Al menos una letra minúscula</p>
                </div>
                <div className={ styles.validator_row +  " " + (!validateUpperCase(value) ? styles.validated : (((validateUpperCase(value) && requestFlag ) ?  styles.error : undefined ))) }>
                    <ValidationIcon />
                    <p>Al menos una letra mayúscula</p>
                </div>
                <div className={ styles.validator_row +  " " + (!validateNumber(value) ? styles.validated : (((validateNumber(value) && requestFlag ) ?  styles.error : undefined ))) }>
                    <ValidationIcon />
                    <p>Al menos un número</p>
                </div>
                <div className={ styles.box_notch }></div>
            </div>
        )
    }

    const renderSuggestionsMobile = (value1, value2) => {
        const val1 = validateMinLength(value1) || validateMinLength(value2); 
        const val2 = validateLowerCharacter(value1) || validateLowerCharacter(value2);
        const val3 = validateUpperCase(value1) || validateUpperCase(value2);
        const val4 = validateNumber(value1) || validateNumber(value2);
        return !appContext.isDesktopOrLaptop && (
            <div className={ `${styles.password_validator} ${styles.active}` }>
                <div className={ styles.validator_row }>
                    <p className={ styles.validator_title }>Tu contraseña debe tener:</p>
                </div>
                <div className={ styles.validator_row  +  " " + (!val1 ? styles.validated : undefined)  }>
                    <ValidationIcon />
                    <p>Al menos 8 caractéres</p>
                </div> 
                <div className={ styles.validator_row +  " " + (!val2 ? styles.validated : undefined) }>
                    <ValidationIcon />
                    <p>Al menos una letra minúscula</p>
                </div>
                <div className={ styles.validator_row +  " " + (!val3 ? styles.validated : undefined) }>
                    <ValidationIcon />
                    <p>Al menos una letra mayúscula</p>
                </div>
                <div className={ styles.validator_row +  " " + (!val4 ? styles.validated : undefined) }>
                    <ValidationIcon />
                    <p>Al menos un número</p>
                </div>
            </div>
        )
    }

    const onClickShowPswdSugg = () => {
        setShowPswSug(true);
        setShowComPswSug(false);
    }

    const onClickShowComPswSugg = () => {
        setShowPswSug(false);
        setShowComPswSug(true);
    }

    return (
        <div className={ styles.verification_wrapper }>
            <p className={ styles.title }>Cambia tu contrase&ntilde;a</p>
            <div className={ styles.instructions_wrapper + " " + styles.small_gap }>
                <p>Ingresa el c&oacute;digo de verificaci&oacute;n que enviamos a <b>{ recoveryInfo.username }</b>,</p>
                <p>y a continuaci&oacute;n, ingresa tu nueva contrase&ntilde;a.</p>
                <br />
                <p>Puede que el correo tarde en llegar unos minutos. Si no lo ves en tu bandeja principal,</p>
                <p>revisa tu carpeta de spam o publicidad.</p>
                <br />
                <p>Si no recibiste el c&oacute;digo, selecciona <b>&quot;Reenviar c&oacute;digo de verificaci&oacute;n&quot;</b>.</p>
            </div>
            <div className={ styles.code_wrapper }>
                <p className={ styles.code_label }>Código de verificación*</p>
                <div className={ styles.code_row } onChange={(event) => handleCodeChange(event) }>
                    {
                        codeInputs.map((codeInput) => codeInput)
                    }
                </div>
                {
                    Object.keys(validationErrors).includes("code") && (
                        <p className={ styles.error_message }>{ validationErrors.code[0] }</p>
                    )
                }
            </div>
            <button className={ styles.resend_button } onClick={ () => handleResendCode() } >
                <img src={ resendIcon } alt="" />
                Reenviar código de verificación
            </button>
            <div className={ styles.instructions_wrapper }>
                <form onChange={(event) => handleFormChange(event) }>
                    <div className={ styles.form_field }>
                        <label>{ appContext.isDesktopOrLaptop ? 'Nueva contraseña*' : 'Contraseña*' }</label>
                        <div className={ styles.password_container + " " + (Object.keys(validationErrors).includes("password") ? styles.error : undefined)}>
                            <input type={ passwordVisibilityFlag ? "password" : "text" } name="password" onFocus = { () => onClickShowPswdSugg() } />
                            <button type='button' onClick={(() => setPasswordVisibilityFlag(!passwordVisibilityFlag))}>
                                { passwordVisibilityFlag ? <HidePassIcon /> : <ShowPassIcon /> } 
                            </button>
                        </div>
                        {
                            Object.keys(validationErrors).includes("password") && (
                                <p className={ styles.error_message }>{ validationErrors.password[0] }</p>
                            )
                        }
                    </div>
                    <div className={ styles.form_field }>
                        <label>Confirmar nueva contraseña*</label>
                        <div className={ styles.password_container + " " + (Object.keys(validationErrors).includes("passwordConfirmation") ? styles.error : undefined)}>
                            <input type={ passwordConfirmationVisibilityFlag ? "password" : "text" } name="passwordConfirmation" onFocus={ () => onClickShowComPswSugg() } />
                            <button type='button' onClick={(() => setPasswordConfirmationVisibilityFlag(!passwordConfirmationVisibilityFlag))}>
                                { passwordConfirmationVisibilityFlag ? <HidePassIcon /> : <ShowPassIcon /> } 
                            </button>
                        </div>
                        {
                            Object.keys(validationErrors).includes("passwordConfirmation") && (
                                <p className={ styles.error_message }>{ validationErrors.passwordConfirmation[0] }</p>
                            )
                        }
                    </div>
                </form>
                { renderSuggestions(showPswSug, recoveryInfo.password, '25%') }
                { renderSuggestions(showComPswSug, recoveryInfo.passwordConfirmation, '-15%') }
                { renderSuggestionsMobile(recoveryInfo.password, recoveryInfo.passwordConfirmation) }
            </div>
            <div className={ styles.buttons_wrapper}>
                <button onClick={ () => handleCancelValidation() }>Cambiar dirección de correo</button>
                <button className={ styles.active } onClick={ () => handleSubmit() }>{ appContext.isDesktopOrLaptop ? 'Cambiar contraseña' : 'Continuar' }</button>
            </div>
        </div>
    )
}
export default VerificationCode;