import styles from '../../pages/candidates/candidateInfo.module.scss'
import stylesH from '../../components/header/header.module.scss'
import Axios from 'axios';
import imageError from '../../../assets/img/image_error.png'

const logUrl = process.env.REACT_APP_BUGLOG_API;
const appName = process.env.REACT_APP_NAME;

export const ErrorFallback = () => {

    return (
        <div className={ styles.modal_wrapper }>
            <div className={ stylesH.header }>
                <div className={ stylesH.wrapper}>
                    <div className={ stylesH.header_logo}>
                        <a href="https://www.mystratis.com" target="_blank">
                            <img src="https://www.mystratis.com/wp-content/themes/stratis/images/logo.svg" alt="Stratis" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={ styles.error_boundary }>
                <label className={ styles.font }>Error al cargar la p&aacute;gina</label>
                <label className={ `${styles.font} ${styles.info}` }>Int&eacute;ntalo de nuevo recargando tu navegador o contacta directamente</label>
                <label className={ `${styles.font} ${styles.info}` }>al equipo de soporte al correo: <b className={ `${styles.font} ${styles.info} ${styles.email}` }>support@mystratis.com</b></label>
                <img src={ imageError } alt='' />
            </div>
        </div>
    )
}

const saveLog = request => Axios.post(`${logUrl}error-logs/new`, request);

export const logError = (error, { componentStack }) => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const bodyRequest = {
        "applicationName": appName,
        "moduleName": appName,
        "errorCode": error.name,
        "errorMessage": error.message,
        "errorDetails": error.stack,
        "severity": "HIGH",
        "reportedBy": userInfo.user.Email,
        "status": "OPEN"
    }
    saveLog(bodyRequest).then(response => {
        console.log(response);
    }).catch(error => {
        console.log(error);
    })
}
