import Axios from 'axios';

const apiUrl = process.env.REACT_APP_JOB_BOARD_API;

const mainContext = 'talent';
const module = 'language';

export const newLanguage = (talentId, workExperience) => Axios.post(`${apiUrl}${mainContext}/${talentId}/${module}/new`, workExperience);

export const editLanguage = (talentId, workExperience) => Axios.put(`${apiUrl}${mainContext}/${talentId}/${module}/edit`, workExperience);

export const getLanguages = talentId => Axios.get(`${apiUrl}${mainContext}/${talentId}/${module}/`);

export const deleteLanguage = (talentId, id) => Axios({
     baseURL: apiUrl,
     url: `${mainContext}/${talentId}/${module}/${id}/delete`,
     method: 'delete',
     headers: {
         'Content-Type': 'application/json'
     },
     data: {}
 });