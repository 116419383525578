import {
    useState,
    useEffect,
    useContext } from 'react';

import { useLocation } from 'react-router-dom';

import styles from './candidateInfo.module.scss';
import notificationStyles from '../../components/notification/notification.module.scss';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import CandidateForms from './candidateForms';
import CandidateResume from './candidateResume';
import ConfirmationMessage from './confirmationMessage';
import Notification from '../../components/notification/notification';
import admirationIcon from '../../../assets/icons/admiration_white.svg';

import { AppContext } from '../../../router/approuter';
import { getDataSession, getTalent } from '../../api/talentClient';
import { CandidateContext } from './context';

const CandidateInfo = () => {
    const appContext = useContext(AppContext);
    const { setPersonalInfoCache } = useContext(CandidateContext);
    let pageLocation = useLocation();

    const [personalInfo, setPersonalInfo] = useState({})
    const [stepIndex, setStepIndex] = useState(0);
    const [notificationData, setNotificationData] = useState({
        notificationStyle: notificationStyles.notification_wrapper
    });
    const [resumeViewFlag, setResumeViewFlag] = useState(false)
    const [careersRedirect, setCareersRedirect] = useState(false)

    const notification = <Notification data={ notificationData } />;

    const displayNotification = (message, isWarn, timer, isErrorConection) => {
        window.scrollTo(0, 0);
        const style = isErrorConection ? notificationStyles.notification_wrapper_error : (isWarn ? notificationStyles.notification_wrapper_warning : notificationStyles.notification_wrapper);
        const icon = isWarn ? admirationIcon : undefined;
        setNotificationData({
            notificationStyle: ( `${ style } ${notificationStyles.active}` ),
            message: message,
            icon: icon,
            isDisplay: true,
            isErrorConection: isErrorConection
        });

        if( !isErrorConection && timer > 0 ) {
            setTimeout(() => {
                setNotificationData({
                    notificationStyle: style,
                    message: message,
                    icon: icon,
                    isDisplay: false
                })
            }, timer);
        }
    }

    const getUserInfo = () => {
        getTalent(appContext.userData.userProfile.talentId).then((response) => {
            const personalInfo = {
                firstName: response.data.result.firstName,
                lastName: response.data.result.lastName,
                position: response.data.result.position,
                description: response.data.result.description,
                phone: response.data.result.phone,
                email: response.data.result.email,
                country: response.data.result.address?.country,
                state: response.data.result.address?.state,
                imgProfile: response.data.result.imgProfile,
                previousJobs: response.data.result.previousJobs,
                educations: response.data.result.educations,
                courses: response.data.result.courses,
                languages: response.data.result.languages,
                skills: response.data.result.skills
            };
            setPersonalInfo(personalInfo);
            setPersonalInfoCache(personalInfo);
        }).catch((response) => {
            console.log(response);
        })
    }

    const checkSession = () => {
        appContext.showLoading(true, 'Cargando', styles.no_scroll);
        getDataSession(appContext.userData.token.id_token, appContext.userData.token.refresh_token).then((response) => {
            appContext.showLoading(false, '', styles.no_scroll);
        }).catch((error) => {
            appContext.showLoading(false, '', styles.no_scroll);
            if( error.response.status === 400 && error.response.data.code === 30005 && error.response.data.msg === 'Token inválido.' ) {
                appContext.setUserData(null);
                localStorage.removeItem('userInfo');
                return appContext.navigate("/login");
            }
        })
    }

    const candidateForm = <CandidateForms 
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        displayNotification={ displayNotification }
        resumeViewFlag={ resumeViewFlag }
        personalInfo={ personalInfo }
        setPersonalInfo={ setPersonalInfo }
        appContext={ appContext } />;

    useEffect(() => {
        // regresando a login si no ha iniciado sesión
        if (!appContext.userData) {
            return appContext.navigate("/login");
        } else {
            checkSession();
        }

        // cargando datos solo si hay talent id
        if (appContext.userData.userProfile.talentId) {
            getUserInfo()
        } else {
            setPersonalInfo({
                firstName: appContext.userData.userProfile.firstName,
                lastName: appContext.userData.userProfile.lastName,
                email: appContext.userData.userProfile.email
            })
        }
        // checando si es redirección de careers o si el usuario ya creó su usario
        // if(pageLocation?.search?.includes("careersRedirect") || (appContext.userData.userProfile.talentId)) {
        if(pageLocation?.search?.includes("careersRedirect")) {
            setCareersRedirect(true);
        }

        if(appContext.userData.userProfile.talentId) {
            setStepIndex(8);
        }
    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ styles.loading_container }>
            <div className={ styles.spinner_container }>
                <div className={ styles.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return(
        <div className={ `${styles.modal_wrapper} ${appContext.modalFlag ? styles.modal_active : ''}` }>
            { renderLoading() }
            { appContext.modalFlag && appContext.modal }
            <Header
                userData={ appContext.userData }
                navigate={ appContext.navigate }
                setUserData={ appContext.setUserData }
                modalFlag={ appContext.modalFlag }
                setModalFlag={ appContext.setModalFlag }
                setModalContent={ appContext.setModalContent }
                stepIndex={ stepIndex }/>
            <div
                className={ appContext.isDesktopOrLaptop ? styles.candidate_info : styles.candidate_info_mobile }
                style={{ background: stepIndex === 9 ? 'linear-gradient(131.35deg, rgba(176, 98, 253, 0.05) 2.12%, rgba(110, 172, 231, 0.17) 99.1%)' : 'white'  }}
            >
                { notification }
                <div className={ styles.wrapper }>
                    {
                        stepIndex <= 7 && candidateForm
                    }
                    {
                        stepIndex === 8 && (
                            <CandidateResume
                                setStepIndex={setStepIndex}
                                setResumeViewFlag={ setResumeViewFlag }
                                personalInfo={ personalInfo }
                                getUserInfo={ getUserInfo }
                                careersRedirect={ careersRedirect }
                                displayNotification={ displayNotification }
                            />)
                    }
                    {
                        stepIndex === 9 && (
                            <ConfirmationMessage
                                personalInfo={ personalInfo }
                                setStepIndex={setStepIndex} />
                        )
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CandidateInfo